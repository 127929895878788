export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const AUTO_LOGIN = "AUTO_LOGIN";

export const GET_DONATIONS = "GET_DONATIONS";
export const DELETE_DONATION = "DELETE_DONATION";
export const ADD_DONATION = "ADD_DONATION";
export const UPDATE_DONATION = "UPDATE_DONATION";

export const GET_VOLUNTEER_ACTIONS = "GET_VOLUNTEER_ACTIONS";
export const GET_ALL_VOLUNTEER_ACTIONS = "GET_ALL_VOLUNTEER_ACTIONS";
export const ADD_VOLUNTEER_ACTION = "ADD_VOLUNTEER_ACTION";
export const UPDATE_VOLUNTEER_ACTION = "UPDATE_VOLUNTEER_ACTION";
export const SET_SEARCH_TERM_VOLUNTEER_ACTIONS = "SET_SEARCH_TERM_VOLUNTEER_ACTIONS";
export const ADD_ACTION_TYPE_ID_VOLUNTEER_ACTIONS = "ADD_ACTION_TYPE_ID_VOLUNTEER_ACTIONS";
export const REMOVE_ACTION_TYPE_ID_VOLUNTEER_ACTIONS = "REMOVE_ACTION_TYPE_ID_VOLUNTEER_ACTIONS";
export const SET_PAGINATION_VOLUNTEER_ACTIONS = "SET_PAGINATION_VOLUNTEER_ACTIONS";
export const DELETE_VOLUNTEER_ACTION = "DELETE_VOLUNTEER_ACTION";

export const GET_VOLUNTEER_ACTIONS_STATUSES = "GET_VOLUNTEER_ACTIONS_STATUSES";

export const GET_VOLUNTEER_ACTION_TYPES = "GET_VOLUNTEER_ACTION_TYPES";
export const DELETE_VOLUNTEER_ACTION_TYPE = "DELETE_VOLUNTEER_ACTION_TYPE";
export const ADD_VOLUNTEER_ACTION_TYPE = "ADD_VOLUNTEER_ACTION_TYPE";
export const UPDATE_VOLUNTEER_ACTION_TYPE = "UPDATE_VOLUNTEER_ACTION_TYPE";

export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const DELETE_SUBSCRIBERS = "DELETE_SUBSCRIBERS";
export const ADD_SUBSCRIBERS = "ADD_SUBSCRIBERS";
export const UPDATE_SUBSCRIBERS = "UPDATE_SUBSCRIBERS";

export const GET_CONTACTS = "GET_CONTACTS";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const ADD_CONTACT = "ADD_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";

export const GET_BANK_ACCOUNT = "GET_BANK_ACCOUNT";
export const UPDATE_BANK_ACCOUNT = "UPDATE_BANK_ACCOUNT";

export const GET_ABOUT_US = "GET_ABOUT_US";
export const UPDATE_ABOUT_US = "UPDATE_ABOUT_US";

export const GET_NEWS = "GET_NEWS";
export const ADD_NEWS = "ADD_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const SET_PAGINATION_NEWS = "SET_PAGINATION_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
